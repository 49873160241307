html {
    background-color: black;
    background-image: linear-gradient(120deg, #c74066, #d998ae);
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0;
    padding: 0;
    background-attachment: fixed;
}

a {
    color: white;
    text-decoration: none;
}

body {
    font: 14px "Century Gothic", Futura, sans-serif;
    color: white;
    letter-spacing: 0.5px;
    margin: 0;
    padding: 0;
}

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    position: relative;
    top: 20px;
    padding: 5px 30px;
}

.navbar-right {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    min-width: fit-content;
}

.navbar-right a {
    background-color: #c74066;
    border-radius: 5px;
    margin-right: 5px;
}

.navbar a {
    text-decoration: none;
    color: white;
    font-size: 20px;
    flex: 1;
    padding: 15px;
    min-width: fit-content; 
    box-sizing: border-box; 
    text-align: center;
}

.navbar-right a:hover, .btn:hover {
    background-color: #d998ae;
}

.navbar a:hover {
    opacity: 0.5;
}

.hamburger, .mobile-menu { 
    display: none;
}

.logo {
    padding: 0 10px;
    margin-left: 20px;
    font-size: 30px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

h1 {
    color: white;
}


.main {
    margin-top: 75px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.main h1 {
    font-size: 4rem;
    margin-top: 0;
    margin-bottom: 20px;
}

.headings {
    max-width: 50%;
}

.main img {
    max-width: 100%;
    width: 400px;
    height: 400px;
}

.main p {
    font-size: 16px;
    font-weight: bold;
}

.btn {
    background-color: #c74066;
    border-radius: 5px;
    padding: 15px 20px;
    color: white;
    font-size: 16px;
    margin-top: 20px;
    cursor: pointer;
}

.links {
    margin-top: 50px;
}

.links a {
    margin-right: 10px;
}

.features-container {
    margin-top: 50px;
}

.features-container h1 {
    text-align: center;
}

.features {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.feature {
    width: 70%;
    display: flex; 
    justify-content: space-between;
    margin: 25px auto;
    border-radius: 5px;
    padding: 50px 50px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.09);
}


.feature-img {
    width: 300px;
    transition: transform 0.3s ease-in-out;
    transform:
        perspective(1000px)
        rotateX(4deg)
        rotateY(-16deg)
        rotateZ(4deg);
    box-shadow: 24px 16px 64px 0 rgba(0, 0, 0, 0.4);
    border-radius: 5px;
    margin-left: 20px;
    
}

.feature-img:hover {
    transform: rotateY(0deg) rotateX(0deg) scale(1.1); /* Animate to normal position and slightly enlarge on hover */
}

.feature a {
    margin-top: 50px;
}

.support {
    text-align: center;
    width: 50%;
    margin: 50px auto;
}

.support h1 {
    margin-top: 50px;
    margin-bottom: 30px;
}

.support p, .support a {
    margin: 30px auto;
}

.footer {
    padding: 10px;
    text-align: center;
    background-color: #c74066;
}

.footer a {
    text-decoration: underline;
}

.hamburger {
    padding: 12px 20px;
    position: relative;
    z-index: 3;
}
  
.mobile-menu {
    right: 0;
    transform: translateX(100%);
    transition: transform 0.3s linear;
}



.bar1, .bar2, .bar3 {
    width: 35px;
    height: 5px;
    background-color: white;
    margin: 4px 0;
    transition: 0.4s;
}

.mobile-menu {
    display: none;
    height: 100vh;
    background-color: #c74066;
    position: absolute;
}


@media screen and (max-width: 900px) {
    .navbar-middle {
        display: none;
    }

    .hamburger {
        display: block;
    }

    .mobile-menu {
        display: none;
        flex-direction: column;
        width: 40%;
        position: absolute;
        z-index: 3;
    }

    .mobile-menu.active {
        display: flex;
        flex-direction: column;
        transform: translateX(0%);
    }
    
    .mobile-menu a {
        color: white;
        text-decoration: none;
        padding: 20px 25px;
        font-size: 20px;
    }
    
    .mobile-menu a:hover {
        background-color: #b4385b;
    }

    .main {
        text-align: center;
        flex-direction: column;
    }

    .main img {
        max-width: 100%;
        width: 300px;
        height: 300px;
        margin-top: 30px;
    }
}

@media screen and (max-width: 750px) {
    .feature {
        flex-direction: column;
        align-items: center;
    }

    .feature img {
        margin: 20px auto;
    }

    .support {
        width: 80%;
    }

    .headings {
        max-width: 80%;
    }

    .headings h1 {
        font-size: 3rem;
    }
}


.simple-profile {
    margin: 50px auto;
    width: 70%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-evenly;
    padding: 50px;
    border-radius: 5px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.09);
}

.simple-profile .avatar img {
    width: 150px;
    height: 150px;
    margin-right: 30px;
    border-radius: 50%;
}

.simple-profile h3 {
    opacity: 0.8;
}

.totalcards {
    text-align: center;
}

.profile-stuff {
    display: flex;
    width: 400px;
}

.inventory-data {
    width: 80%;
    margin: 50px auto;
    border-radius: 5px;
    align-items: center;
    padding: 50px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.09);
}

.invcards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.inventory-header {
    display: grid;
    align-items: center;
    margin-bottom: 30px;
}

.inventory-header h2 {
    align-self: center;
    text-align: center;
}

.inventory-data img {
    width: 200px;
    height: 268px;
    margin: auto;
}

.card {
    display: flex;
    flex-direction: column;
    margin: 10px;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.09);
    background-image: repeating-linear-gradient(36deg, rgba(255,255,255, 0.2), rgba(255,255,255, 0.1) 1px, transparent 0px, transparent 2px);
    width: 250px;
    height: 425px;
}

.card-data {
    margin-left: 20px;
}

.card-data h3 {
    height: 40px;
}

.card-data h4 {
    margin: 0;
    opacity: 0.9;
}

.rarity {
    opacity: 1;
    font-weight: 100;
}

.filters-button {
    display: flex;
    height: 50px;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
}

.filter-button {
    border-radius: 5px;
    height: 50px;
    display: flex;
    align-items: center;
    border: none;
    background-color: #c74066;
    color: white;
    font-size: 20px;
    cursor: pointer;
    padding: 15px 20px;
}

.filters-button img {
    filter: invert(1);
    height: 20px;
    width: 20px;
    margin: auto;
    margin-right: 5px;
}

.filters-settings {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 500px;
    text-align: center;
}

.sort-button {
    background: none;
    border: none;
    font-size: 16px;
    cursor: pointer;
    text-decoration: underline;
    color: white;
}

.filters-settings select {
    background: none;
    border: none;
    font-size: 16px;
    cursor: pointer;
    color: white;
    margin: 0 10px;
}

.filters-settings option {
    background: #b4385b;
    border: none;
    font-size: 16px;
    cursor: pointer;
    padding: 5px;
    color: white;
}

.filters-settings .option-hover {
    background-color: #d998ae;
}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    position: relative;
    top: 20px;
    padding: 5px 30px;
}

.pagination-button {
    background: none;
    border: none;
    font-size: 16px;
    cursor: pointer;
    color: white;
    margin: 0 5px;
}

.pagination-button.num {
    padding: 20px;
    border-radius: 50%;
}

.pagination-button.arrow-button {
    border-radius: 5px;
    height: 50px;
    display: flex;
    align-items: center;
    border: none;
    background-color: #c74066;
    color: white;
    font-size: 20px;
    cursor: pointer;
    padding: 15px 20px;
}

.pagination-input {
    background: none;
    border: none;
    font-size: 16px;
    cursor: pointer;
    color: white;
    margin: 0 5px;
    width: 30px;
    text-align: center;
}

.pagination-input::placeholder {
    color: white;
}

.filter-menu {
    margin: 150px auto;
    width: 40%;
    display: flex;
    align-items: center;
    background-color: #f35380;
    border-radius: 5px;
    padding: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.09);
    flex-direction: column;
    font-weight: bold;
}

.filters {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.autocomplete-input {
    padding: 7px;
    border: none;
    border-radius: 5px;
    width: 300px;
}

.filter-submit {
    width: 100px;
    margin-left: auto;
    margin-right: auto;
    border: none;
}

.filter-rarity {
    display: flex;
    margin-top: 10px;
    align-items: center;
}

.rarity-input {
    background: none;
    border: white solid;
    border-radius: 5px;
    text-align: right;
    margin-left: 10px;
    height: 25px;
    color: white;
}

.suggestions-list {
    position: absolute;
    left: 0; 
    right: 0; 
    
    margin-left: auto; 
    margin-right: auto;
    margin-top: 0;
    width: 320px;
    max-height: 150px;
    overflow: auto;
    color: black;
    background-color: white;
    border: #d998ae solid;
    border-radius: 5px;
    padding: 0;
    list-style-type: none;
}

.suggestion {
    padding: 10px;
}

.suggestion:hover {
    background-color: rgb(122, 122, 254);
    cursor: pointer;
}

.filter-submit.clear {
    background: none;
    color: white;
    margin-top: 20px;
    border: none;
    font-size: 16px;
    cursor: pointer;
    text-decoration: underline;
}

.fiter-submit-btns {
    display: flex;
    justify-content: center;
}

.cards-num-bottom {
    display: none;
}

@media screen and (max-width: 760px) {
    .cards-num {
        display: none;
    }

    .filters-button {
        justify-content: center;
    }

    .card img {
        width: 150px;
        height: 201px;
    }

    .card h3 {
        font-size: 0.9rem;
    }

    .card h4 {
        font-size: 0.7rem;
    }

    .card {
        width: 170px;
        padding: 5px;
        margin: 5px;
        height: 350px;
    }

    .cards-num-bottom {
        display: block;
        text-align: center;
    }

    .filter-menu {
        width: 60%;
    }

    .folder-select .autocomplete-input {
        width: 400px !important;
        padding: 5px !important;
        font-size: 0.9rem !important;

    }

    .folder-select .suggestions-list {
        width: 420px !important;
    }
}


table {
    border-collapse: collapse;
    width: 100%;
    margin: auto;
    font-size: 1.2rem;
    margin-bottom: 50px;
}

.commands-container {
    width: 60%;
    margin: auto;
}

.commands h1 {
    text-align: center;
    margin: 30px auto;
}

th, td {
    text-align: left;
    padding: 8px;
    border: rgba(255, 255, 255, 0.5) solid;
}

/* HTML: <div class="loader"></div> */
.loader {
    width: 50px;
    aspect-ratio: 1;
    border-radius: 50%;
    border: 8px solid;
    border-color: #ffffff #ffffff00;
    animation: l1 1s infinite;
  
}

@keyframes l1 {to{transform: rotate(.5turn)}}

.folder-select {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 50px auto;
    padding: 25px 50px;
    width: 70%;
    border-radius: 5px;
    
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.09);
    font-weight: bold;
}


.folder-select .autocomplete-input {
    width: 600px;
    padding: 10px;
    font-size: 1.1rem;
    background-image: url(downarrow.svg);
    background-size: 16px 16px;
    background-repeat: no-repeat;
    background-position: 98%;
}

  

.folder-select .suggestions-list {
    width: 620px;
}

.privacy-policy {
    margin: 50px 100px;
}

.privacy-policy a {
    text-decoration: underline;
}